<template>
  <template v-if="isList" class="">
    <div class="btn btn-outline-dark border-0 me-2 me-md-0" v-if="isFavorite" @click="toggleFavorite()">
      <font-awesome-icon :icon="['fas', 'heart']"/>
    </div>
    <div class="btn btn-outline-dark border-0  me-2 me-md-0" v-if="!isFavorite" @click="toggleFavorite()">
      <font-awesome-icon :icon="['far', 'heart']"/>
    </div>
  </template>
  <template v-if="!isList">
    <div v-if="isFavorite">
      <div class="btn btn-secondary opacity-75 d-block px-2 px-md-1" style="font-size: 0.9rem;"
           @click="toggleFavorite()">
        <font-awesome-icon :icon="['fas', 'heart']"/>
        {{ wordings[currentLanguage].RM_FAVORITE }}
      </div>
    </div>
    <div v-if="!isFavorite">
      <div class="btn btn-secondary opacity-75 d-block px-2 px-md-1" style="font-size: 0.9rem;"
           @click="toggleFavorite()">
        <font-awesome-icon :icon="['far', 'heart']"/>
        {{ wordings[currentLanguage].ADD_FAVORITE }}
      </div>
    </div>
  </template>

</template>

<script>
import {defineComponent} from "vue";

import {addIcons} from "ionicons";
import {bookmark, bookmarkOutline} from "ionicons/icons";
import {mapActions, mapState} from "vuex";

import {LocalNotifications} from '@capacitor/local-notifications';

addIcons({"bookmark-outline": bookmarkOutline, bookmark});

export default defineComponent({
  data() {
    return {
      isFavorite: false,
      activeId: null,
      notificationTime: null,
      actionSheetSelection: null,
      utcOffset: '+00:00'
    };
  },
  components: {},
  props: ["favId", "parentId", "isList"],
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion'])
  },
  methods: {
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList', 'saveItem', 'togglePresentation', 'toggleRemoteFavorite', 'toggleSession']),
    ...mapActions("settings", [
      "getSetting",
    ]),
    async toggleFavorite() {
      let favorites = await this.getItem('favorites_' + this.currentConference);
      favorites = favorites || [];
      if (favorites.indexOf(this.activeId) === -1) {
        favorites.push(this.activeId);
        this.isFavorite = true;
        await this.saveFavoriteList(favorites)
      } else {
        favorites.splice(favorites.indexOf(this.activeId), 1);
        this.isFavorite = false;
        await this.saveFavoriteList(favorites);
      }
      await this.toggleRemoteFavorite(this.activeId)
      if (this.activeId.indexOf('sessions/') != -1) {
        await this.toggleSession(this.activeId)
      }
      if (this.activeId.indexOf('presentations/') != -1) {
        let params = {sessId: this.parentId, presId: this.activeId};
        await this.togglePresentation(params);
      }

      this.prepareFavoriteNotifications();
    },


    async checkFavorite(id) {
      let favorites = await this.getItem('favorites_' + this.currentConference);
      favorites = favorites || [];
      if (favorites.indexOf(id) != -1) {
        this.activeId = id;
        this.isFavorite = true;
      }
    },

    async saveFavoriteList(favorites) {
      console.log(favorites)
      await this.saveItem({name: 'favorites_' + this.currentConference, item: favorites})

    },

    async prepareFavoriteNotifications() {

      let confFavorites = await this.getItem('favorites_' + this.currentConference);
      confFavorites = confFavorites || [];

      let favorites = await this.prepareFavoriteList(confFavorites);

      let favItems = null;
      let favSessions = favorites.sessions || [];
      let favPresentations = favorites.presentations || [];

      favItems = favSessions.concat(favPresentations)

      let notificationsArray = []
      for (let i = 0; i < favItems.length; i++) {
        let notificationId = parseInt(favItems[i].id.substring(favItems[i].id.length - 12)) //ID MUST BE PARSED TO INT!! OTHERWISE THEY WONT BE RECOGNIZED AS DIFERENT IDS
        let notificationTime = new Date(new Date(favItems[i].start_time) - 600000);
        let notificationItem = {
          title: favItems[i].title,
          body: "This session will start in 10 minutes",
          id: notificationId,
          schedule: {
            at: notificationTime,
            allowWhileIdle: true,
          }
        }
        notificationsArray.push(notificationItem);
      }

      let pending = null;
      await LocalNotifications.getPending().then((res) => pending = res);
      LocalNotifications.cancel({
        notifications: pending.notifications
      });
      LocalNotifications.schedule({
        notifications: notificationsArray
      });
    },

  },


  async created() {
    this.utcOffset = await this.getSetting('timezone');
    this.activeId = this.favId
    this.checkFavorite(this.favId)
  },
});
</script>
<style lang="scss">
.loader {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ion-spinner {
    margin-right: 10px;
  }
}

.favorite-button {
  width: 100%;
  background: #ccd3d3;
  color: #7b7979;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
}
</style>
