<template>

  <div class="col mb-2 ms-2 ms-md-0 " v-if="!session.isHead">
    <div class="card h-100 session-item" :style="{'border-color':  session.color + ' !important'}">
      <div class="position-absolute top-0 end-0 me-2">
        <FavoriteButton :fav-id="'sessions/' + session.id" v-if="session.id" is-list="true" ></FavoriteButton>
      </div>

      <div class="card-body">

        <div>
          <a @click="showDisclaimer(session)">
            <div class="head" >
              <div class="type d-md-none" v-if="session.code" >{{ session.code }}</div>
              <div class="type d-md-none">{{ session.session_type_text }}</div>
              <div class="time"
                   v-if="session.location">
                {{ moment(session.start_time).format('dddd HH:mm') }} - {{
                  moment(session.end_time).format('HH:mm')
                }} {{ timezoneString }}
                <span v-if="session.location">| {{ session.location.name }}</span></div>
              <!--div class="code" v-if="session.code">[{{session.code}}]</div-->

            <!--div class="favorite-button"><favorite-button  v-if="session.id"> </favorite-button> </div-->

            </div>

            <div class="content">
              <div class="title">{{ session.title }}</div>
              <div class="chair d-none d-md-block" v-if="session.chair && session.chair.length > 0">
                <strong>Chair:</strong> <span
                  v-for="chair in session.chair"
                  :key="chair">{{
                  chair.display_name
                }}</span>
              </div>
              <div class="speaker d-none d-md-block" style="overflow: hidden; max-height:100px"
                   v-if="session.speakers && session.speakers.length > 0"><strong>Speakers: </strong>
                <span v-for="speaker in session.speakers"
                      :key="speaker">{{ speaker.first_name }} {{ speaker.last_name }}</span></div>
              <div v-if="session.note">My Note: <em>&quot;{{ session.note }}&quot;</em></div>
            </div>

            <div class="indicator" v-if="session.liveSessionActive">[ LIVE ]</div>
          </a>


        </div>
        <!--hr class="mt-2 mb-0 d-md-none" -->
      </div>
      <div class="code-wrapper d-md-none"
           :style="{'color': session.font_color, 'background-color':session.color}">
      </div>
      <div class="card-footer d-none d-md-block"
           :style="{'color': session.font_color, 'background-color':session.color}">
        <div class="footer-text">{{ session.session_type_text }}</div>
         <div class="footer-text">{{ session.code }}</div>
      </div>

    </div>

  </div>
  <div v-if="session.isHead" class="col-12 mb-1" style="width:100vw; padding-left:15px">
    <h4 class="my-0"> {{ moment(session.start_time).format('HH:mm') }}</h4>

  </div>
</template>
<script>
import moment from 'moment';
import {alertController} from "@ionic/vue";
import {mapActions, mapState} from "vuex";
import FavoriteButton from './favorite/FavoriteButton.vue';
//moment.locale("de");
export default {
  components: {FavoriteButton}, //{ FavoriteButton },
  data() {
    return {
      timezoneString:'CET'
    }
  },
  props: ['session'],
  async created() {
    this.moment = moment;
    this.moment.locale(this.currentLanguage || 'en');
    this.timezoneString = await this.getSetting('timezone_label');

  },
  computed:{
    ...mapState('wordings', ['wordings', 'currentLanguage']),

  },
  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),
    ...mapActions("settings", [
      "getSetting",
    ]),
    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    if(session.display_type && session.display_type == 'collection'){
                      this.$router.push('/app/collections/' + session.id);
                    }else{
                      this.$router.push('/app/sessions/' + session.id);
                    }

                  },
                },
              ],
            });
        return alert.present();
      } else {
        if(session.display_type && session.display_type == 'collection'){
          this.$router.push('/app/collections/' + session.id);
        }else{
          this.$router.push('/app/sessions/' + session.id);
        }
      }

    },
  },

};
</script>

<style lang="scss">
.session-item {
  font-size: 0.9rem;
  border-radius: 20px;
  position: relative !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;

  .card-footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    line-height: 1rem;

    .footer-text {
      font-size: 0.8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .head {
    font-size: 0.8rem;
    padding-bottom: 5px;
    color: grey;
    width: 100%;

    .type {
      color: grey;
      width: 80%;
    }

   

    .time {
      //padding-top: 5px;
      line-height: 1rem;
      width: 80%;
    }
  }
   .favorite-button{
      position: absolute;
      top: 10px;
      width: 20%;
      right:20px;
    }


  .content {
    .title {
      font-weight: 500;
      line-height: 1.1rem;
      padding-bottom: 10px;
    }

    .chair {
      font-size: 0.8rem;
      color: grey;
      line-height: 1.0rem;
      span:not(:last-child)::after {
        content: ", ";
      }
    }

    .speaker {
      color: grey;
      font-size: 0.8rem;
      line-height: 1.0rem;
      span:not(:last-child)::after {
        content: ", ";
      }
    }

  }

  .code-wrapper {
    position: absolute;
    right: 0;
    height: 20px;
    font-size: 0.8rem;
    width: 55px;
    border-top-right-radius: 20px;

    .code {
      white-space: nowrap;
      display: block;
      width: 20px;
      height: inherit;
      padding-left: 10px;

    }
  }
}

@media(max-width: 767px) {
  .session-item {
    border-radius: 0;
    border: none;
    //box-shadow: none !important;
    box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 5%) !important;

    .card-body {
      padding: 5px 20px;
    }

    .head {
      .time {
        padding-top: 0;
      }
    }

    .content {
      .title {
        font-weight: 400;
      }
    }

    .code-wrapper {
      position: absolute;
      left: 5px;
      height: inherit;
      width: 8px;
      border-radius: 5px;

      .code {
        writing-mode: tb-rl;
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
        white-space: nowrap;
        display: block;
        width: 20px;
        height: inherit;
        padding-top: 10px;
        padding-left: 0;
      }
    }
  }
}
</style>
